<!-- Title -->
<h3 class="ui-portal-title">
  User Management ({{ model.data.total || 0 }})

  <div class="ui-portal-title-buttons">
    <!-- Filter button -->
    <button
      class="btn-gray"
      [class.toggled]="isPopupFilters"
      ui-scroll-visibility="260"
      (click)="isPopupFilters = !isPopupFilters"
      (onHide)="hideFilters()"
    >
      <span class="ui-icon-filter mr10" [class.active]="isAnyFilter()"></span>Filters
    </button>

    @if (isEdit()) {
      <!-- Cancel button -->
      <button class="btn-gray" (click)="toggleEditMode()">Cancel</button>

      <!-- Save button -->
      <button class="btn-primary" (click)="save(grid)">Save</button>
    } @else {
      <!-- Add button -->
      <button class="btn-gray" (click)="addNewUser()">Add new</button>

      <!-- Edit button -->
      <button class="btn-gray" (click)="toggleEditMode()"><span class="ui-icon-edit mr10"></span>Edit</button>
    }
  </div>
</h3>

<ui-filter
  #filterComponent
  [items]="model.filters || []"
  [term]="model.search"
  [wrap]="true"
  [isSearch]="false"
  [storeFilters]="true"
  storeKey="csh-filters-users"
  [showAsPopup]="isPopupFilters"
  (onSearch)="onSearch($event)"
  (onChange)="onFilter($event)"
></ui-filter>

<!-- Grid -->
<ui-grid
  #grid
  [data]="model.data"
  [config]="conf"
  [fixedRows]="1"
  [fixedCols]="1"
  [fixedRowOffset]="50"
  [columns]="columnsDef"
  [editable]="isEdit()"
  [noWrap]="true"
  (onSort)="onSorting($event)"
  (onAction)="onGridAction($event)"
  (onNextPageLoad)="loadPage($event)"
>
  <!-- Roles column -->
  <ng-template #rolesColumn let-row="row" let-col="col">
    @for (r of row[col.field]; track r) {
      <span class="txt-role">{{ r }}</span>
    }
  </ng-template>
</ui-grid>
